import { Injectable } from '@angular/core';
import { Observable, of, Subject, merge } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map, filter, switchMap, mergeMap, distinctUntilChanged, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firestore } from 'firebase';
import { SubscriptionStatus } from './subscription.service';
import { NavController } from '@ionic/angular';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly LOCAL_STORAGE_KEY_USER_ID = 'userId';

  readonly FIRESTORE_COLLECTION_USERS = 'users';

  readonly FIRESTORE_COLLECTION_REVOKED_USER_TOKENS = 'revokedUserTokens';

  readonly current$: Observable<User>;

  readonly claims$: Observable<UserClaims>;

  readonly isLoggedIn$: Observable<boolean>;

  private users: AngularFirestoreCollection<User>;

  private revokedUserTokens: AngularFirestoreCollection<RevokedUserToken>;

  private userToken: string;

  private signedInSubject: Subject<void>;

  constructor(
    private auth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private httpClient: HttpClient,
    private navController: NavController,
    private appService: AppService
  ) {

    this.signedInSubject = new Subject();

    this.auth.idToken.subscribe(idToken => {
      this.userToken = idToken;
    });

    this.users = this.angularFirestore.collection(this.FIRESTORE_COLLECTION_USERS);

    this.revokedUserTokens = this.angularFirestore.collection(this.FIRESTORE_COLLECTION_REVOKED_USER_TOKENS);

    this.isLoggedIn$ = this.auth.user.pipe(
      map(user => user != null),
      startWith(this.getUserId() != null),
      distinctUntilChanged()
    );

    this.auth.user.subscribe(user => {
      if (user != null) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY_USER_ID, user.uid);
      }
    });

    this.current$ = this.auth.user.pipe(
      mergeMap(user => {
        if (user == null) {
          return of(null);
        }

        return merge(
          // wait for login to finish completely to avoid permission errors from Firestore rules
          this.signedInSubject.asObservable(),
          // fire immediately if user is already logged in
          of(this.getUserId()).pipe(filter(userId => userId != null)),
        ).pipe(switchMap(() => this.getUser(user.uid))
        );
      })
    );

    this.claims$ = this.auth.idTokenResult.pipe(
      map(idTokenResult => {
        if (idTokenResult == null) {
          return null;
        }

        const claims = idTokenResult.claims;
        return {
          id: claims.user_id,
          email: claims.email,
          subscriptionStatus: claims.subscriptionStatus,
          subscriptionId: claims.subscriptionId,
          subscriptionExpiryTimestamp: claims.subscriptionExpiryTimestamp,
          emailVerified: claims.email_verified,
          isAdmin: claims.isAdmin,
          isSuperAdmin: claims.isSuperAdmin
        };

      })
    );

  }

  public getUserId(): string {
    return localStorage.getItem(this.LOCAL_STORAGE_KEY_USER_ID);
  }

  public async refreshToken(): Promise<string> {
    const user = await this.auth.currentUser;
    return user.getIdToken(true);
  }

  public getUserToken(): string {
    return this.userToken;
  }

  private getUser(id: string): Observable<User> {
    return this.users.doc<User>(id).snapshotChanges().pipe(
      this.appService.handleFirestorePermissionError('user', false, () => this.signOut()),
      filter(action => !action.payload.metadata.hasPendingWrites),
      map(action => {
        if (!action.payload.exists) {
          return null;
        }

        const user = action.payload.data({ serverTimestamps: 'estimate' });
        return { ...user, id };
      })
    );
  }

  async register(user: UserRegistration): Promise<void> {
    const url = `${environment.apiUrl}/users/register`;
    await this.httpClient.post(url, user).toPromise();
  }

  async signIn(credentials: UserLogin): Promise<firebase.User> {
    const authCreds = await this.auth.signInWithEmailAndPassword(credentials.email, credentials.password);
    await this.clearRevokedTokens(authCreds.user.uid);
    this.signedInSubject.next();
    return authCreds.user;
  }

  private async clearRevokedTokens(userId: string) {
    const docRef = this.revokedUserTokens.doc(userId).ref;
    const doc = await docRef.get();
    if (doc.exists) {
      await docRef.delete();
    }
  }

  async signOut() {
    await this.auth.signOut();
    localStorage.clear();
    this.navController.navigateRoot(['login']);
  }

  async sendPasswordResetEmail(email: string) {
    await this.auth.sendPasswordResetEmail(email);
  }

}

export type UserClaims = {
  id: string;
  email: string;
  subscriptionStatus: SubscriptionStatus;
  subscriptionExpiryTimestamp: number;
  subscriptionId: string;
  emailVerified: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  [k: string]: any;
};

export type UserLogin = {
  email: string;
  password: string
};

export type UserRegistration = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type User = {
  id: string;
  lastUpdateTimestamp: firestore.Timestamp;
  firstName: string;
  lastName: string;
  evaluationCount: number;
};

export type RevokedUserToken = {
  revokeTimestamp: firestore.Timestamp;
};
