// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-dev.calm-investing.com/v1',
  wordpress: {
    apiUrl: 'https://calm-investing.com/wp-json/wp/v2',
    homePageId: '608'
  },
  exchangeRates: {
    apiUrl: 'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1'
  },
  firebase: {
    apiKey: 'AIzaSyDe_0EgglwB0moChIHfCTz-oPzYBVmpOmk',
    authDomain: 'calm-investing-dev-90b4f.firebaseapp.com',
    databaseURL: 'https://calm-investing-dev-90b4f.firebaseio.com',
    projectId: 'calm-investing-dev',
    storageBucket: 'calm-investing-dev.appspot.com',
    messagingSenderId: '351221723408',
    appId: '1:351221723408:web:4f14df8c3a0a4e366ff595',
    measurementId: 'G-Q3PPZ1X9KF'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
