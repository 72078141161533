import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { UserClaims } from 'src/app/user.service';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent implements OnInit, OnChanges {

  @Input()
  claims: UserClaims;

  config: UserBadgeConfig;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.claims && !this.claims) {
      return;
    }

    this.config = this.resolveConfig(this.claims);
  }

  resolveConfig(claims: UserClaims): UserBadgeConfig {
    if (claims.isSuperAdmin) {
      return { color: 'dark', label: 'super', tooltipProps: {
        content: 'Super Administrator'
      }};
    } else if (claims.isAdmin) {
      return { color: 'dark', label: 'admin', tooltipProps: {
        content: 'Administrator'
      }};
    } else {

      switch (claims.subscriptionStatus) {
        case 'active':
          return { color: 'primary', label: 'pro', tooltipProps: {
            content: 'Subscriber'
          }};
        case 'inactive':
          return { color: 'medium', label: 'trial', tooltipProps: {
            content: 'Trial Period'
          }};
        case 'expired':
        case 'void':
          return { color: 'danger', label: 'void', tooltipProps: {
            content: 'No Active Subscription'
          }};
      }
    }
  }

}

type UserBadgeConfig = {
  color: string;
  label: string;
  tooltipProps: NgxTippyProps;
};
